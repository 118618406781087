import React from "react";
import { WEB_URL } from "../constants";
import { Container, Card, Row, Col, Image } from "react-bootstrap";

const TaskInfo = ({ task }) => {
  return (
    <Container className="mt-2">
      <Card>
        <Card.Body>
          <Row>
            <Col md={6}>
              <Card.Text>{task.taskName}</Card.Text>
              <Card.Text>
                <strong>Staff:</strong> {task.taskBy}
              </Card.Text>
              <Card.Text>
                <strong>Date:</strong> {new Date(task.taskDate).toLocaleDateString()}
              </Card.Text>
            </Col>
            <Col md={6}>
              {task.images.length > 0 ? (
                <Row>
                  {task.images.map((image, index) => (
                    <Col md={4} key={index} className="mb-2 mt-2">
                      <Image src={`${WEB_URL}public/tasks/${image}`} fluid />
                    </Col>
                  ))}
                </Row>
              ) : (
                <p>No images available</p>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TaskInfo;
