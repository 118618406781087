import React from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "../redux/authSlice";

import { Card, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTasks,
  faMoneyBill,
  faWallet,
  faReceipt,
  faRupee,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";

const Dashboard = () => {
  const { userinfo } = useSelector(selectAuth);
  //   //   const requestHeader = {
  //   //     headers: {
  //   //       Authorization: `Bearer ${authToken}`,
  //   //       "Content-Type": "application/json",
  //   //     },
  //   //   };

  return (
    <div className="dashboard-app">
      <Row>
        {userinfo?.usertype === 1 && (
          <>
            <Col md={6} lg={6} xs={6}>
              <a href="/task-management">
                <Card className="mb-4">
                  <Card.Body>
                    <Row>
                      <Col className="text-center theme-color">
                        <FontAwesomeIcon icon={faPlusCircle} size="3x" />
                        <Card.Text className="font-weight-bold">Add Tasks</Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </a>
            </Col>
            <Col md={6} lg={6} xs={6}>
              <a href="/daily-tasks">
                <Card className="mb-4">
                  <Card.Body>
                    <Row>
                      <Col className="text-center theme-color">
                        <FontAwesomeIcon icon={faTasks} size="3x" />
                        <Card.Text className="font-weight-bold">Daily Tasks</Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </a>
            </Col>
          </>
        )}
        {userinfo?.usertype === 2 && (
          <>
            <Col md={6} lg={6} xs={6}>
              <Card className="mb-4">
                <Card.Body>
                  <Row>
                    <Col className="text-center theme-color">
                      <FontAwesomeIcon icon={faMoneyBill} size="3x" />
                      <Card.Text className="font-weight-bold">
                        Monthly <br />
                        Expences
                      </Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={6} xs={6}>
              <Card className="mb-4">
                <Card.Body>
                  <Row>
                    <Col className="text-center theme-color">
                      <FontAwesomeIcon icon={faWallet} size="3x" />
                      <Card.Text className="font-weight-bold">
                        Staff <br />
                        Salary
                      </Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={6} xs={6}>
              <Card className="mb-4">
                <Card.Body>
                  <Row>
                    <Col className="text-center theme-color">
                      <FontAwesomeIcon icon={faReceipt} size="3x" />
                      <Card.Text className="font-weight-bold">
                        Receipt <br />
                        Upload
                      </Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </>
        )}
        {userinfo?.usertype === 3 && (
          <>
            <Col md={6} lg={6} xs={6}>
              <a href="/task-management">
                <Card className="mb-4">
                  <Card.Body>
                    <Row>
                      <Col className="text-center theme-color">
                        <FontAwesomeIcon icon={faPlusCircle} size="3x" />
                        <Card.Text className="font-weight-bold">Add Tasks</Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </a>
            </Col>
            <Col md={6} lg={6} xs={6}>
              <a href="/daily-tasks">
                <Card className="mb-4">
                  <Card.Body>
                    <Row>
                      <Col className="text-center theme-color">
                        <FontAwesomeIcon icon={faTasks} size="3x" />
                        <Card.Text className="font-weight-bold">Daily Tasks</Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </a>
            </Col>
          </>
        )}
        {userinfo?.usertype === 4 && (
          <>
            <Col md={6} lg={6} xs={6}>
              <a href="/daily-tasks">
                <Card className="mb-4">
                  <Card.Body>
                    <Row>
                      <Col className="text-center theme-color">
                        <FontAwesomeIcon icon={faTasks} size="3x" />
                        <Card.Text className="font-weight-bold">Daily Tasks</Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </a>
            </Col>
            <Col md={6} lg={6} xs={6}>
              <a href="/finance">
                <Card className="mb-4">
                  <Card.Body>
                    <Row>
                      <Col className="text-center theme-color">
                        <FontAwesomeIcon icon={faRupee} size="3x" />
                        <Card.Text className="font-weight-bold">Finance</Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </a>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default Dashboard;
