import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectAuth, logUserOut } from "../redux/authSlice";
import { APP_URL } from "../constants";

import { Form, Button, Col } from "react-bootstrap";
import axios from "axios";

const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const TaskForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userinfo, authToken } = useSelector(selectAuth);
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const [taskName, setTaskName] = useState("");
  const [taskBy, setTaskBy] = useState("Anirudh");
  const [taskDate, setTaskDate] = useState(getTodayDate());
  const [images, setImages] = useState([]);
  const staffOptions = ["Anirudh", "House Keeping", "Gardener"];

  const handleFileChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("taskName", taskName);
    formData.append("taskBy", taskBy);
    formData.append("taskDate", taskDate);
    images.forEach((image) => {
      formData.append("images[]", image);
    });

    try {
      const response = await axios.post(`${APP_URL}tasks`, formData, requestHeader);
      if (response?.data?.taskId) {
        navigate(`/task/${response?.data?.taskId}`);
      } else {
        alert("Something went wrong !");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (userinfo?.usertype && userinfo?.usertype !== 1 && userinfo?.usertype !== 3) {
      dispatch(logUserOut());
      navigate("/");
    }
  }, [dispatch, navigate, userinfo?.usertype]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mt-2" as={Col} controlId="formTaskName">
        <Form.Label>Task Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter task name"
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mt-2" as={Col} controlId="formTaskBy">
        <Form.Label>Staff</Form.Label>
        <Form.Control
          required
          as="select"
          value={taskBy}
          onChange={(e) => setTaskBy(e.target.value)}
        >
          <option value="">Select a staff member</option>
          {staffOptions.map((staff) => (
            <option>{staff}</option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group className="mt-2" controlId="formTaskDate">
        <Form.Label>Task Date</Form.Label>
        <Form.Control
          required
          type="date"
          value={taskDate}
          onChange={(e) => setTaskDate(e.target.value)}
          max={getTodayDate()}
        />
      </Form.Group>

      <Form.Group className="mt-2" controlId="formImages">
        <Form.Label>Images</Form.Label>
        <Form.Control required type="file" multiple onChange={handleFileChange} />
      </Form.Group>
      <Form.Group className="mt-2">
        <Button variant="info" type="submit" className="add-form-btn">
          Submit
        </Button>
      </Form.Group>
    </Form>
  );
};

export default TaskForm;
