import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";

const Finance = ({ task }) => {
  return (
    <Container className="mt-2">
      <Card>
        <Card.Body>
          <Row>
            <Col md={6}>
              <Card.Text as="h4" className="text-center text-info">
                Coming Soon
              </Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Finance;
