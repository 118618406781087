import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Navbar, Button, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { fetchUserInfo, logUserOut, selectAuth } from "../redux/authSlice";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faTasks,
  faSignOut,
  faMoneyBill,
  faReceipt,
  faWallet,
  faRupee,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const { isAuthenticated, loading: userLoading, userinfo } = useSelector(selectAuth);
  const navigate = useNavigate();
  const location = useLocation();
  const isLoginPage = location.pathname === "/";

  const loggedUser = () => {
    dispatch(logUserOut());
    navigate("/");
  };

  useEffect(() => {
    if (!userLoading && !isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, userLoading]);

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  if (isLoginPage) {
    return <>{children}</>;
  }

  return (
    <div className="custom-app">
      {userLoading && (
        <div className="page-loader">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      <Navbar bg="light" expand="lg" fixed="top" className="custom-header bg-light shadow">
        <Navbar.Brand href="/dashboard">Hello, {userinfo?.name}</Navbar.Brand>
        <Button variant="outline-primary" className="ml-auto" onClick={loggedUser}>
          <FontAwesomeIcon icon={faSignOut} />
        </Button>
      </Navbar>
      <Container className="custom-child">{children}</Container>
      <div className="w-100 text-cyan text-center webniter-color">
        Powered by{" "}
        <a target="_blank" href="https://www.webniter.com/" className="white-cyan">
          Webniter
        </a>
      </div>
      <footer className="custom-footer bg-light shadow">
        <div className="custom-footer-buttons">
          <Button as="a" href="/dashboard" variant="outline-primary" className="footer-buttons">
            <FontAwesomeIcon icon={faHome} />
          </Button>
          {userinfo?.usertype === 1 && (
            <>
              <Button
                as="a"
                href="/task-management"
                variant="outline-primary"
                className="footer-buttons"
              >
                <FontAwesomeIcon icon={faPlusCircle} />
              </Button>

              <Button
                as="a"
                href="/daily-tasks"
                variant="outline-primary"
                className="footer-buttons"
              >
                <FontAwesomeIcon icon={faTasks} />
              </Button>

              <Button variant="outline-primary" className="footer-buttons" onClick={loggedUser}>
                <FontAwesomeIcon icon={faSignOut} />
              </Button>
            </>
          )}

          {userinfo?.usertype === 2 && (
            <>
              <Button variant="outline-primary" className="footer-buttons">
                <FontAwesomeIcon icon={faMoneyBill} />
              </Button>
              <Button variant="outline-primary" className="footer-buttons">
                <FontAwesomeIcon icon={faWallet} />
              </Button>
              <Button variant="outline-primary" className="footer-buttons">
                <FontAwesomeIcon icon={faReceipt} />
              </Button>
              <Button variant="outline-primary" className="footer-buttons" onClick={loggedUser}>
                <FontAwesomeIcon icon={faSignOut} />
              </Button>
            </>
          )}

          {userinfo?.usertype === 3 && (
            <>
              <Button
                as="a"
                href="/task-management"
                variant="outline-primary"
                className="footer-buttons"
              >
                <FontAwesomeIcon icon={faPlusCircle} />
              </Button>
              <Button
                as="a"
                href="/daily-tasks"
                variant="outline-primary"
                className="footer-buttons"
              >
                <FontAwesomeIcon icon={faTasks} />
              </Button>

              <Button variant="outline-primary" className="footer-buttons" onClick={loggedUser}>
                <FontAwesomeIcon icon={faSignOut} />
              </Button>
            </>
          )}

          {userinfo?.usertype === 4 && (
            <>
              <Button
                as="a"
                href="/daily-tasks"
                variant="outline-primary"
                className="footer-buttons"
              >
                <FontAwesomeIcon icon={faTasks} />
              </Button>
              <Button as="a" href="/finance" variant="outline-primary" className="footer-buttons">
                <FontAwesomeIcon icon={faRupee} />
              </Button>
              <Button variant="outline-primary" className="footer-buttons" onClick={loggedUser}>
                <FontAwesomeIcon icon={faSignOut} />
              </Button>
            </>
          )}
        </div>
      </footer>
    </div>
  );
};

export default Layout;
