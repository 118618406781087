import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import TaskInfo from "./TaskInfo";

import { useSelector } from "react-redux";
import { selectAuth } from "../redux/authSlice";

import { APP_URL } from "../constants";

import { Container, Row, Col, Spinner } from "react-bootstrap";

const Dashboard = () => {
  const { authToken } = useSelector(selectAuth);
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };
  const [startDate, setStartDate] = useState(new Date());
  const [tasks, setTasks] = useState(null);
  const [loading, setLoading] = useState(true);
  const today = new Date();

  const handleDateChange = (date) => {
    const onlyDate = formatDate(date);
    setStartDate(onlyDate);
    makeAjaxCall(onlyDate);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  const makeAjaxCall = async (date) => {
    setLoading(true);
    axios
      .get(`${APP_URL}tasks/date/${date}`, requestHeader)
      .then((response) => {
        const taskInfos = response?.data?.map((task) => ({
          ...task,
          images: JSON.parse(task.images), // Parse the images JSON string to an array
        }));
        setTasks(taskInfos);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (authToken) {
      handleDateChange(new Date());
    }
  }, [authToken]);

  return (
    <div className="dashboard-app">
      <Row>
        <Col md={12} lg={12} xs={12}>
          <h6>Select a Date</h6>
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            isClearable
            showYearDropdown
            scrollableMonthYearDropdown
            className="form-control"
            maxDate={today}
          />
        </Col>
        {loading && (
          <Container className="text-center mt-5">
            <Spinner animation="border" variant="info" />
            <p>Loading...</p>
          </Container>
        )}
        {tasks?.length > 0 ? (
          tasks?.map((task) => <TaskInfo task={task} />)
        ) : (
          <p>No tasks available.</p>
        )}
      </Row>
    </div>
  );
};

export default Dashboard;
