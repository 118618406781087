let api_endpoint = "";
let server_endpoint = "";
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
  api_endpoint = "http://127.0.0.1:8000/api/";
  server_endpoint = "http://127.0.0.1:8000/";
} else {
  api_endpoint = "https://vrm.webniter.com/v1/api/";
  server_endpoint = "https://vrm.webniter.com/v1/";
}
export const APP_URL = api_endpoint;
export const WEB_URL = server_endpoint;
