import React from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "../redux/authSlice";

import { Card, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBroom,
  faTree,
  faHomeAlt,
  faCog,
  faTasks,
  faUserClock,
} from "@fortawesome/free-solid-svg-icons";

const Dashboard = () => {
  const { userinfo } = useSelector(selectAuth);
  //   //   const requestHeader = {
  //   //     headers: {
  //   //       Authorization: `Bearer ${authToken}`,
  //   //       "Content-Type": "application/json",
  //   //     },
  //   //   };

  return (
    <div className="dashboard-app">
      <Row>
        {userinfo?.usertype === 3 && (
          <>
            <Col md={6} lg={6} xs={6}>
              <a href="/attendance">
                <Card className="mb-4">
                  <Card.Body>
                    <Row>
                      <Col className="text-center theme-color">
                        <FontAwesomeIcon icon={faUserClock} size="3x" />
                        <Card.Text className="font-weight-bold">Attendance</Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </a>
            </Col>
            <Col md={6} lg={6} xs={6}>
              <Card className="mb-4">
                <Card.Body>
                  <Row>
                    <Col className="text-center theme-color">
                      <FontAwesomeIcon icon={faHomeAlt} size="3x" />
                      <Card.Text className="font-weight-bold">Society</Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={6} xs={6}>
              <Card className="mb-4">
                <Card.Body>
                  <Row>
                    <Col className="text-center theme-color">
                      <FontAwesomeIcon icon={faCog} size="3x" />
                      <Card.Text className="font-weight-bold">Services</Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={6} xs={6}>
              <Card className="mb-4">
                <Card.Body>
                  <Row>
                    <Col className="text-center theme-color">
                      <FontAwesomeIcon icon={faBroom} size="3x" />
                      <Card.Text className="font-weight-bold">Housekeeping</Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={6} xs={6}>
              <Card className="mb-4">
                <Card.Body>
                  <Row>
                    <Col className="text-center theme-color">
                      <FontAwesomeIcon icon={faTree} size="3x" />
                      <Card.Text className="font-weight-bold">Gardener</Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={6} xs={6}>
              <Card className="mb-4">
                <Card.Body>
                  <Row>
                    <Col className="text-center theme-color">
                      <FontAwesomeIcon icon={faTasks} size="3x" />
                      <Card.Text className="font-weight-bold">Tasks</Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default Dashboard;
