import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { APP_URL } from "../constants";
import { useSelector } from "react-redux";
import { selectAuth } from "../redux/authSlice";

import TaskInfo from "./TaskInfo";

import { Container, Spinner } from "react-bootstrap";

const Task = () => {
  const { taskId } = useParams(); // Get taskId from URL parameters
  const { authToken } = useSelector(selectAuth);
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };
  const [task, setTask] = useState(null); // State to store task details
  const [loading, setLoading] = useState(true); // State to handle loading
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    // Fetch task details from the API
    const fetchTask = async () => {
      try {
        const response = await axios.get(`${APP_URL}tasks/${taskId}`, requestHeader);
        let taskInfo = { ...response.data, images: JSON.parse(response.data.images) };
        setTask(taskInfo);
        setLoading(false);
      } catch (error) {
        setError("Error fetching task details");
        setLoading(false);
      }
    };

    fetchTask();
  }, [taskId]);

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" variant="info" />
        <p>Loading...</p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="text-center mt-5">
        <p className="text-danger">{error}</p>
      </Container>
    );
  }

  return (
    <Container>
      <TaskInfo task={task} />
    </Container>
  );
};

export default Task;
