import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";

import Layout from "./Components/Layout";
import Login from "./Components/Login";
import Dashboard from "./Components/Dashboard";
import Attendance from "./Components/Attendance";
import TaskForm from "./Components/TaskForm";
import Task from "./Components/Task";
import DailyTasks from "./Components/DailyTasks";
import Finance from "./Components/Finance";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

export default function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
          <Route
            path="/attendance"
            element={
              <Layout>
                <Attendance />
              </Layout>
            }
          />

          <Route
            path="/task-management"
            element={
              <Layout>
                <TaskForm />
              </Layout>
            }
          />
          <Route
            path="/task/:taskId"
            element={
              <Layout>
                <Task />
              </Layout>
            }
          />
          <Route
            path="/daily-tasks"
            element={
              <Layout>
                <DailyTasks />
              </Layout>
            }
          />
          <Route
            path="/finance"
            element={
              <Layout>
                <Finance />
              </Layout>
            }
          />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
