import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { Form, Button, Alert } from "react-bootstrap";
import "../assets/css/login.css";
import { APP_URL } from "../constants";

import BackgroundImage from "../assets/images/bg-profile.jpeg";

const Login = () => {
  const dispatch = useDispatch();
  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [formError, setFormError] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const credentials = {
      username: inputUsername,
      password: inputPassword,
    };

    try {
      const response = await axios.post(`${APP_URL}society-login`, credentials);
      if (response?.data?.status === true && response?.data?.token) {
        setFormError("");
        const authToken = response?.data?.token;
        if (authToken) {
          dispatch(login({ authToken }));
          navigate("/dashboard");
        } else {
          setFormError(response?.data?.message);
        }
      } else {
        setFormError(response?.data?.message);
      }
      setLoading(false);
    } catch (error) {
      console.error(error.response.data);
      setLoading(false);
    }
  };

  return (
    <div className="sign-in__wrapper" style={{ backgroundImage: `url(${BackgroundImage})` }}>
      {/* Overlay */}
      <div className="sign-in__backdrop"></div>
      {/* Form */}
      <Form className="shadow p-4 bg-white rounded" onSubmit={handleSubmit}>
        {/* Header */}
        <div className="h4 mb-2 text-center">V.R. Meadows</div>
        {/* ALert */}
        {show ? (
          <Alert className="mb-2" variant="danger" onClose={() => setShow(false)} dismissible>
            Incorrect username or password.
          </Alert>
        ) : (
          <div />
        )}
        <Form.Group className="mb-2" controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            value={inputUsername}
            placeholder="Username"
            onChange={(e) => setInputUsername(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={inputPassword}
            placeholder="Password"
            onChange={(e) => setInputPassword(e.target.value)}
            required
          />
        </Form.Group>
        {!loading ? (
          <Button className="w-100" variant="primary" type="submit">
            Log In
          </Button>
        ) : (
          <Button className="w-100" variant="primary" type="submit" disabled>
            Logging In...
          </Button>
        )}
        <Form.Control.Feedback type="invalid" className="d-block text-center">
          {formError}
        </Form.Control.Feedback>
      </Form>
      {/* Footer */}
      <div className="w-100 mb-2 position-absolute bottom-0 start-50 translate-middle-x text-white text-center">
        Powered by{" "}
        <a target="_blank" href="https://www.webniter.com/" className="white-text">
          Webniter
        </a>
      </div>
    </div>
  );
};

export default Login;
