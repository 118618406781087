import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APP_URL } from "../constants";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("authState");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("authState", serializedState);
  } catch (error) {
    // Handle errors here
  }
};

const initialState = loadState() || {
  isAuthenticated: false,
  authToken: null,
  userinfo: null,
  loading: false,
  error: null,
};

export const logUserOut = createAsyncThunk("api/society-logout", async (_, { getState }) => {
  const token = getState().auth.authToken;
  if (token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      axios
        .post(`${APP_URL}society-logout`, null, { headers })
        .then((response) => {
          // Handle successful response
          return { userinfo: null, authToken: null, response: response };
        })
        .catch((error) => {
          // Handle error
          console.error("Error:", error);
        });
    } catch (error) {
      // Handle network errors or other issues
      throw error;
    }
  }
});

export const fetchUserInfo = createAsyncThunk("api/societyinfo", async (_, { getState }) => {
  const token = getState().auth.authToken;
  if (token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await fetch(`${APP_URL}societyinfo`, { headers });
      if (response.ok) {
        const userinfo = await response.json();
        return {
          userinfo: userinfo?.user,
          authToken: token,
        };
      } else {
        return { userinfo: null, authToken: null };
      }
    } catch (error) {
      // Handle network errors or other issues
      throw error;
    }
  }
});

export const handleInvalidToken = () => (dispatch) => {
  dispatch(logout());
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.authToken = action.payload?.authToken;
      saveState(state);
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.authToken = null;
      state.loading = false;
      state.error = null;
      state.userinfo = null;
      saveState(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.isAuthenticated = false;
        state.loading = true;
        state.error = null;
        state.userinfo = null;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.isAuthenticated = action?.payload?.userinfo ? true : false;
        state.loading = false;
        state.error = null;
        state.userinfo = action?.payload?.userinfo ?? null;
        state.authToken = action?.payload?.authToken ?? null;
        saveState(state);
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.userinfo = null;
        state.authToken = null;
        state.loading = false;
        state.error = action?.error?.message;
        saveState(state);
      });
  },
});

export const { login, logout } = authSlice.actions;
export const selectAuth = (state) => state.auth;

export default authSlice.reducer;
